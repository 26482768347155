import { publicPath } from './fixWebpackPublicPath';

// TODO: don't create if someone has already added a style element?
let resolveLoaded: (() => void) | undefined;
let rejectLoaded: (() => void) | undefined;
let hasLoaded: 'loading' | 'error' | 'loaded' = 'loading';
let promise: Promise<void> | undefined;

const vaCss = document.createElement('link');
vaCss.rel = 'stylesheet';
vaCss.href = `${publicPath}style.css`;

// In jest, the CSS fails to load but is not needed.
// @ts-ignore
if (window.sas?.ltjs?.jest) {
  hasLoaded = 'loaded';
} else {
  document.head.appendChild(vaCss);
}
vaCss.onload = function () {
  hasLoaded = 'loaded';
  resolveLoaded?.();
  // style.css has a font face for sas-icons
  for (const font of document.fonts) {
    if (font.family === 'sas-icons') {
      if (font.status === 'error' || font.status === 'loaded') {
        break;
      }
      // Our supported browsers have this, but it marked as experimental on MDN.
      font.load?.();
      break;
    }
  }
};
vaCss.onerror = function () {
  console.error('va-sdk:ERROR: css failed to load');
  hasLoaded = 'error';
  rejectLoaded?.();
};

export function whenCssLoaded(): Promise<void> {
  if (hasLoaded === 'loaded') {
    return Promise.resolve();
  } else if (hasLoaded === 'error') {
    return Promise.reject();
  }
  if (!promise) {
    promise = new Promise((resolve, reject) => {
      resolveLoaded = resolve;
      rejectLoaded = reject;
    });
  }
  return promise;
}
