import { __assign } from "tslib";
import { getBrowserName, BROWSER } from './util/browserUtil';
// TODO(mikcol): callbacks could be typed better
function fireCallbacks(callbacks, context, args) {
    if (!callbacks || callbacks.length === 0) {
        return;
    }
    for (var i = 0, length_1 = callbacks.length; i < length_1; ++i) {
        callbacks[i].apply(context, args);
    }
}
var Ajax = /** @class */ (function () {
    function Ajax(urlOrOptions, maybeOptions) {
        var _this = this;
        this.callbacks = {};
        this.status = 0;
        var overrideOptions;
        if (typeof urlOrOptions === 'object') {
            overrideOptions = urlOrOptions;
        }
        else {
            overrideOptions = maybeOptions || {};
            overrideOptions.url = urlOrOptions;
        }
        var options = __assign({ url: '', headers: null, data: null, dataType: '*', contentType: null, type: 'GET', async: true }, overrideOptions);
        // options.contentType should override options.headers
        if (options.contentType !== null) {
            options.headers = __assign(__assign({}, options.headers), { 'Content-Type': options.contentType });
        }
        // Omit is only supported with fetch.
        if (options.extraOptions && options.extraOptions.credentials === 'omit') {
            fetch(options.url, {
                method: options.type,
                headers: options.headers || undefined,
                body: options.type !== 'GET' ? options.data : undefined,
            }).then(function (response) {
                return response.text().then(function (responseText) {
                    _this.state = null;
                    _this.status = response.status;
                    _this.statusText = response.statusText;
                    _this.data = responseText;
                    _this.responseText = responseText;
                    _this._responseHeaders = response.headers;
                    var responseArgs;
                    if (response.status === 200) {
                        _this.state = 'done';
                        responseArgs = [responseText, response.statusText, _this];
                        fireCallbacks(_this.callbacks.done, _this, responseArgs);
                    }
                    else {
                        _this.state = 'fail';
                        responseArgs = [_this, null, response.statusText];
                        fireCallbacks(_this.callbacks.fail, _this, responseArgs);
                    }
                    fireCallbacks(_this.callbacks.always, _this, responseArgs);
                });
            });
            return;
        }
        var xhr = new XMLHttpRequest();
        this.xhr = xhr;
        xhr.onreadystatechange = function () {
            _this.state = null;
            _this.status = xhr.status;
            _this.statusText = xhr.statusText;
            _this.data = xhr.response;
            _this.responseText = xhr.responseText;
            if (xhr.readyState === 4) {
                // Special case: In Edge (non-chromium), 300 redirects fail when non safe headers are present. As a work around, make a request to
                //    the base service so that the authentication redirects can happen. Then retry the request.
                if (getBrowserName() === BROWSER.EDGE &&
                    xhr.status === 0 &&
                    options.extraOptions &&
                    options.extraOptions.credentials === 'include' &&
                    !options.extraOptions._ignoreFailedRequest) {
                    var url = new URL(options.url);
                    var requestUrl = url.origin + url.pathname.substr(0, url.pathname.indexOf('/', 1) + 1);
                    var request = new Ajax(requestUrl, {
                        extraOptions: { credentials: 'include', _ignoreFailedRequest: true },
                    });
                    request.always(function () {
                        //remake original request
                        var reRequest = new Ajax(__assign(__assign({}, options), { extraOptions: __assign(__assign({}, options.extraOptions), { _ignoreFailedRequest: true }) }));
                        reRequest.callbacks = _this.callbacks;
                    });
                    return;
                }
                var responseArgs = void 0;
                if (xhr.status === 200) {
                    _this.state = 'done';
                    responseArgs = [xhr.response, xhr.statusText, _this];
                    fireCallbacks(_this.callbacks.done, _this, responseArgs);
                }
                else {
                    _this.state = 'fail';
                    responseArgs = [_this, null, xhr.statusText];
                    fireCallbacks(_this.callbacks.fail, _this, responseArgs);
                }
                fireCallbacks(_this.callbacks.always, _this, responseArgs);
                _this.callbacks.args = responseArgs;
            }
        };
        // TODO(mikcol): do we need to check against options.dataType here (as long as we map '*' to '')?
        if (options.async && options.dataType !== '*' && options.dataType !== '') {
            // Synchronous requests from a document must not set a response type.
            // TODO(mikcol): still need this check at runtime, need to type things better above
            // tslint:disable-next-line:strict-type-predicates
            if (options.dataType === undefined || options.dataType === null) {
                xhr.responseType = '';
            }
            else {
                xhr.responseType = options.dataType;
            }
        }
        if (options.extraOptions && options.extraOptions.credentials === 'include') {
            xhr.withCredentials = true;
        }
        xhr.open(options.type, options.url, options.async);
        if (options.headers) {
            for (var key in options.headers) {
                if (options.headers.hasOwnProperty(key)) {
                    xhr.setRequestHeader(key, options.headers[key]);
                }
            }
        }
        if (options.data !== null) {
            xhr.send(options.data);
        }
        else {
            xhr.send();
        }
        return this;
    }
    Ajax.prototype.done = function (callback) {
        if (this.state === 'done') {
            callback.apply(this, this.callbacks.args);
        }
        else {
            if (!this.callbacks.done) {
                this.callbacks.done = [];
            }
            this.callbacks.done.push(callback);
        }
        return this;
    };
    Ajax.prototype.fail = function (callback) {
        if (this.state === 'fail') {
            callback.apply(this, this.callbacks.args);
        }
        else {
            if (!this.callbacks.fail) {
                this.callbacks.fail = [];
            }
            this.callbacks.fail.push(callback);
        }
        return this;
    };
    Ajax.prototype.always = function (callback) {
        if (this.callbacks.args) {
            callback.apply(this, this.callbacks.args);
        }
        else {
            if (!this.callbacks.always) {
                this.callbacks.always = [];
            }
            this.callbacks.always.push(callback);
        }
        return this;
    };
    Ajax.prototype.then = function () {
        return this.done.apply(this, arguments);
    };
    Ajax.prototype.error = function () {
        return this.fail.apply(this, arguments);
    };
    Ajax.prototype.getAllResponseHeaders = function () {
        if (this.xhr) {
            return this.xhr.getAllResponseHeaders();
        }
        if (this._responseHeaders) {
            var result_1 = '';
            this._responseHeaders.forEach(function (val, key) {
                result_1 += key + ": " + val + "\r\n";
            });
            return result_1;
        }
        return '';
    };
    return Ajax;
}());
export { Ajax };
