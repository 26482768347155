import { AbstractReportElement } from './AbstractReportElement';
import { SASReportObjectProps } from '../components/SASReportObject';
import { getImports } from './dynamicImports';
import type { ObjectHandle } from '../handles';

export class SASReportObjectElement extends AbstractReportElement<SASReportObjectProps> {
  protected _getRenderer() {
    return getImports()?.renderers.SASReportObject;
  }

  static get observedAttributes() {
    return [...super.observedAttributes, 'objectname', 'reportcontextkey'];
  }

  protected getRenderProps(): SASReportObjectProps | null {
    const commonProps = this.getCommonProps();

    if (commonProps && this.objectName) {
      return {
        ...commonProps,
        objectName: this.objectName,
        reportContextKey: this.reportContextKey,
      };
    } else {
      return null;
    }
  }

  get objectName() {
    return this.getAttribute('objectName');
  }
  set objectName(value) {
    if (value && typeof value === 'string') {
      this.setAttribute('objectName', value);
    } else {
      this.removeAttribute('objectName');
    }
  }

  get reportContextKey() {
    return this.getAttribute('reportContextKey') || undefined;
  }

  set reportContextKey(value) {
    if (value && typeof value === 'string') {
      this.setAttribute('reportContextKey', value);
    } else {
      this.removeAttribute('reportContextKey');
    }
  }

  private _objectHandlePromise?: Promise<ObjectHandle>;
  private _rejectObjectHandleCallback?: (reason: string) => void;
  protected _invalidateHandleRequests(reason: string) {
    super._invalidateHandleRequests(reason);
    this._rejectObjectHandleCallback?.(reason);
    this._rejectObjectHandleCallback = undefined;
    this._objectHandlePromise = undefined;
  }
  getObjectHandle(): Promise<ObjectHandle> {
    const objectName = this.objectName;
    if (!objectName) {
      return Promise.reject('Cannot get an ObjectHandle before setting objectName');
    }
    if (!this._objectHandlePromise) {
      this._objectHandlePromise = Promise.race([
        // Resolve with the handle for the current object name
        this.getReportHandle().then((handle) => handle.getObjectHandle(objectName)),
        // Or reject if the element attributes change
        new Promise<ObjectHandle>((_accept, reject) => {
          this._rejectObjectHandleCallback = reject;
        }),
      ]);
    }
    return this._objectHandlePromise;
  }
}
