import { publicPath } from './fixWebpackPublicPath';
import { loadScript } from '@sas-dvr/ltjs-commons/util/ltjsLoaderUtil';
import * as WebElements from './elements';
import '@sas/nova-commons/embedded.css';
import './loadCss';
import { displayImagesForMobile } from './elements/dynamicImports/isMobile';

declare const ROOT_CLASS_NAME: string;

const customElementCSS = `
sas-report, sas-report-object, sas-report-page {
  display: block;
}

sas-report, sas-report-page {
  min-width: 50px;
  min-height: 50px;
}
`;

// Generate css for custom elements
{
  const style = document.createElement('style');
  style.appendChild(document.createTextNode(customElementCSS));
  document.head.appendChild(style);
}

if (displayImagesForMobile()) {
  import('./elements/dynamicImports/mobileImportsImpl').then(({ renderers }) => {
    (window as any).vaReportComponents = {
      ...renderers,
    };
    const event = new CustomEvent('vaReportComponents.loaded');
    dispatchEvent(event);
  });
} else {
  //
  // Load LTJS
  // Set the ltjslibpath relative to this loader script.
  // sas.ltjs is initialized as part of the ltjsLoaderUtil
  //
  (window as any).sas.ltjs.resourceUrl = publicPath + 'assets/';
  // va-sdk UMD builds exclude ICU assets, so always use browser collation
  (window as any).sas.ltjs.useBrowserCollation = true;
  loadScript([]);
  import('./elements/dynamicImports/dynamicImportsImpl').then(
    ({
      SASReport,
      SASReportObject,
      SASReportPage,
      registerDataDrivenContent,
      connectToServer,
      setUseHighContrastReportTheme,
      setLoadingTheme,
      initRootStyles,
      setLocale,
      setFormatterLocale,
    }) => {
      initRootStyles(ROOT_CLASS_NAME);

      //TODO: Type validation?
      (window as any).vaReportComponents = {
        //TODO: remove the undocumented react exports?
        // We could shim etc. the other exports and remove the need for vaReportComponents.loaded (but maybe keep it around)
        SASReport,
        SASReportObject,
        SASReportPage,
        ...WebElements,
        registerDataDrivenContent,
        connectToServer,
        setUseHighContrastReportTheme,
        setLoadingTheme,
        setLocale,
        setFormatterLocale,
      };
      const event = new CustomEvent('vaReportComponents.loaded');
      dispatchEvent(event);
    }
  );
}
