import * as ltjsEnvironment from './ltjsEnvironment';
import * as ltjsAjax from './Ajax';
import { isUrlCrossDomain, getUriParameter } from './util/urlUtil';
import { BROWSER, getBrowserName } from './util/browserUtil';
export * from './util/urlUtil';
export * from './util/browserUtil';
var Ajax = ltjsAjax.Ajax;
var isOpenUI = sas.ltjs.isOpenUI;
var FakeIdentitiesAjax = /** @class */ (function () {
    function FakeIdentitiesAjax() {
        var _this = this;
        this.callbacks = [];
        setTimeout(function () {
            for (var _i = 0, _a = _this.callbacks; _i < _a.length; _i++) {
                var cb = _a[_i];
                cb(FakeIdentitiesAjax.response);
            }
        }, 0);
    }
    FakeIdentitiesAjax.prototype.always = function (cb) {
        this.callbacks.push(cb);
    };
    FakeIdentitiesAjax.response = {
        status: 200,
        getAllResponseHeaders: function () {
            return '';
        },
        responseText: JSON.stringify({
            /* eslint-disable shikari/sas-i18n-ems */
            description: '__FAKE_DESCRIPTION__',
            id: '__FAKE_ID__',
            links: [],
            name: '__FAKE_NAME__',
            providerId: '__FAKE_PROVIDERID__',
            state: 'active',
            /* eslint-enable shikari/sas-i18n-ems */
            version: 1,
        }),
    };
    return FakeIdentitiesAjax;
}());
export function warn() {
    if (console.warn) {
        console.warn.apply(console, arguments);
    }
    else {
        console.log.apply(console, arguments);
    }
}
export function assert(expression, message) {
    if (isOpenUI) {
        jQuery.sap.assert(expression, message);
    }
    else if (window.console) {
        if (console.assert) {
            // tslint:disable-next-line:no-unsafe-any
            console.assert(!!expression, message);
        }
        else if (!expression) {
            console.log(message);
        }
    }
}
function _getBrowserLocale(locale) {
    if (!locale && !sas.ltjs.node) {
        if (window.navigator.languages) {
            locale = window.navigator.languages[0];
        }
        else if (window.navigator.language) {
            locale = window.navigator.language;
        }
        else {
            // TODO(mikcol): better access to IE props? (userLanguage is IE)
            // tslint:disable-next-line:no-unsafe-any
            locale = window.navigator.userLanguage;
        }
    }
    if (!locale || locale === 'en') {
        locale = 'en-US';
    }
    return locale;
}
function _getLanguageTag(locale) {
    locale = _getBrowserLocale(locale);
    // Avoid circular dependencies with ClosetUtil
    var LanguageTag = ltjsEnvironment.moduleLookup('sas/ltjs/commons/resources/localization/LanguageTag');
    return new LanguageTag(locale);
}
function getNormalizedLanguageTag(locale) {
    // When loading nls properties bundles ltjs might not have been initialized yet
    if (!ltjsEnvironment.isInitialized()) {
        locale = _getBrowserLocale(locale);
        locale = locale.replace('_', '-');
        var parts = locale.split('-');
        var language = parts[0].toLowerCase(); // en
        var region = parts.length > 1 ? '-' + parts[1].toUpperCase() : ''; // US
        var variant = parts.length > 2 ? '-' + parts[2].toLowerCase() : ''; // pseudo
        if (region === '' && language === 'en') {
            return 'en-US';
        }
        // special case. When Chrome is set to Chinese with no region it reports
        // the locale as 'zh' which is not supported in portable because traditional
        // and simplified characters are very different and more specificity is
        // needed. va-sdk uses zh-CN in that case so we'll do that here as well.
        if (region === '' && language === 'zh') {
            return 'zh-CN';
        }
        return language + region + variant;
    }
    else {
        var languageTag = _getLanguageTag(locale);
        return languageTag.asCanonicalString(); // never returns null
    }
}
export function getLegacyLanguageTag(locale) {
    var languageTag = _getLanguageTag(locale);
    if (languageTag.isPseudo()) {
        return languageTag.asCanonicalString(); // never returns null
    }
    return languageTag.getSASLegacyLocale(); // never returns null
}
export function getPackageLocales() {
    var legacyLocale = getLegacyLanguageTag(getLocale());
    var legacyFormatLocale = getLegacyLanguageTag(getFormatLocale());
    if (legacyLocale === legacyFormatLocale) {
        return [legacyLocale];
    }
    else {
        return [legacyLocale, legacyFormatLocale];
    }
}
export function getLocalizedString(bundleName, property, args) {
    // Avoid circular dependencies with ClosetUtil
    var ResourceManager = ltjsEnvironment.moduleLookup('sas/ltjs/commons/resources/ResourceManager');
    var resourceString = ResourceManager.getLocalizedString(bundleName, property);
    if (resourceString && args) {
        for (var i = 0, len = args.length; i < len; ++i) {
            resourceString = resourceString.replace("{" + i + "}", args[i]);
        }
    }
    return resourceString || property;
}
var _locale = null;
export function getLocale() {
    var _a;
    if (!_locale) {
        if (isOpenUI) {
            // tslint:disable-next-line:no-unsafe-any
            if (sas.registry && sas.registry.wip) {
                // Work-around for S1496768
                _locale = getFormatLocale();
            }
            else {
                // tslint:disable-next-line:no-unsafe-any
                _locale = sap.ui
                    .getCore()
                    .getConfiguration()
                    .getLanguageTag();
            }
        }
        else if (sas.ltjs.node) {
            _locale = (_a = sas.ltjs.node.locale) !== null && _a !== void 0 ? _a : null;
        }
        else {
            _locale = getUriParameter('nova-language') || null;
        }
        _locale = getNormalizedLanguageTag(_locale);
    }
    return _locale;
}
var _formatLocale = null;
export function getFormatLocale() {
    var _a;
    if (!_formatLocale) {
        if (isOpenUI) {
            // tslint:disable-next-line:no-unsafe-any
            _formatLocale = sap.ui
                .getCore()
                .getConfiguration()
                .getFormatLocale();
        }
        else if (sas.ltjs.node) {
            _formatLocale = (_a = sas.ltjs.node.formatLocale) !== null && _a !== void 0 ? _a : null;
        }
        _formatLocale = getNormalizedLanguageTag(_formatLocale);
    }
    return _formatLocale;
}
export function ajax() {
    if (isOpenUI) {
        var ajaxConfig = arguments[0];
        if (getUriParameter('sas-commons-configUrl')) {
            var url = ajaxConfig.url;
            if (url && url.indexOf('http://localhost') === 0) {
                ajaxConfig.crossOrigin = true;
                return jQuery.ajax(ajaxConfig);
            }
        }
        var isGet = !ajaxConfig.type || ajaxConfig.type.toLowerCase() === 'get';
        var browserName = getBrowserName();
        if ((browserName === BROWSER.IE || browserName === BROWSER.EDGE) && isGet) {
            // S1189104: Cache-bust IE in case 'Automatically' is set to true.
            ajaxConfig.cache = false;
        }
        // tslint:disable-next-line:no-unsafe-any
        return sas.ajax.apply(window, arguments);
    }
    else {
        var arg0 = arguments[0];
        var arg1 = arguments[1] || {};
        //FIXME: HACK because identities is not rebuilding for 19w35.
        if (arg0 instanceof Object && arg0.url && arg0.url.endsWith('/identities/users/@currentUser')) {
            return new FakeIdentitiesAjax();
        }
        return new Ajax(arg0, arg1);
    }
}
/**
 * Creates an HTMLImageElement element and sets properties prior to setting src.
 * @param {string} url - Value to set as image.src
 * @param {object} properties - Set on the element prior to setting src.
 * @returns {HTMLImageElement} - Fetched image element
 */
export function getImage(url, properties, credentialedSource) {
    var image = new Image();
    if (isUrlCrossDomain(url)) {
        image.crossOrigin = 'anonymous';
    }
    if (url && url.indexOf('http://localhost') === 0) {
        image.crossOrigin = '';
    }
    if (credentialedSource) {
        image.crossOrigin = "use-credentials";
    }
    for (var property in properties) {
        if (properties.hasOwnProperty(property)) {
            image[property] = properties[property];
        }
    }
    image.src = url;
    return image;
}
var isTransportAuthenticated = false;
export function getSASAjaxConfig(url) {
    var sasAjaxConfig = {};
    // Component case.
    // tslint:disable-next-line:no-unsafe-any
    if (sas.ltjs.forceCrossDomainAuthentication) {
        sasAjaxConfig.crossDomain = true;
        sasAjaxConfig.authenticate = true;
    }
    else if (!isTransportAuthenticated) {
        // S1161802: We need to authenticate the first request from transport.
        if (url.indexOf('getUserCapabilities') > -1) {
            sasAjaxConfig.crossDomain = true;
            sasAjaxConfig.authenticate = true;
            isTransportAuthenticated = true;
        }
    }
    return sasAjaxConfig;
}
/* tslint:disable:no-unsafe-any */
/**
 * This is mostly for IE to help with clearing DOM nodes.
 * @param {DOMElement} d
 */
export function purge(d) {
    var a = d.attributes;
    if (a) {
        for (var i = a.length - 1; i >= 0; i -= 1) {
            var n = a[i].name;
            if (typeof d[n] === 'function') {
                d[n] = null;
            }
        }
    }
    var children = d.childNodes;
    if (children) {
        var l = children.length;
        for (var i = 0; i < l; i += 1) {
            purge(children[i]);
        }
    }
}
/* tslint:enable:no-unsafe-any */
