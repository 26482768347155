// isMobile and loadCss should be the only non-type import.
import { displayImagesForMobile } from './isMobile';
import { whenCssLoaded } from '../../loadCss';

import type { FC } from 'react';
import type { SASReportProps } from '../../components/SASReport';
import type { convertThemeServiceResponseToThemeParameterOverrides } from '@sas/nova-commons/design-tokens';
import type { SASReportObjectProps } from '../../components/SASReportObject';
import type { SASReportPageProps } from '../../components/SASReportPage';
import type { extendStoreLifetime, releaseStoreLifetime } from '../../internal/ReportStoreManager';

export type RenderersType = {
  SASReport: FC<SASReportProps>;
  SASReportObject: FC<SASReportObjectProps>;
  SASReportPage: FC<SASReportPageProps>;
};
export type DynamicType = {
  renderers: RenderersType;
  convertThemeServiceResponseToThemeParameterOverrides?: typeof convertThemeServiceResponseToThemeParameterOverrides;
  extendStoreLifetime?: typeof extendStoreLifetime;
  releaseStoreLifetime?: typeof releaseStoreLifetime;
};

function firedImportsLoadedCallbacks() {
  if (elementsToInvalidate) {
    for (const callback of elementsToInvalidate.values()) {
      callback();
    }
  }
  elementsToInvalidate = undefined;
}

if (displayImagesForMobile()) {
  Promise.all([import('./mobileImportsImpl'), whenCssLoaded()]).then(([impl]) => {
    imports = {
      renderers: impl.renderers,
      convertThemeServiceResponseToThemeParameterOverrides: undefined,
      extendStoreLifetime: undefined,
      releaseStoreLifetime: undefined,
    };
    firedImportsLoadedCallbacks();
  });
} else {
  Promise.all([import('./dynamicImportsImpl'), whenCssLoaded()]).then(([imp]) => {
    const {
      convertThemeServiceResponseToThemeParameterOverrides,
      extendStoreLifetime,
      releaseStoreLifetime,
      ...renderers
    } = imp;
    imports = {
      renderers,
      convertThemeServiceResponseToThemeParameterOverrides,
      extendStoreLifetime,
      releaseStoreLifetime,
    };
    firedImportsLoadedCallbacks();
  });
}

let imports: DynamicType | undefined;
let elementsToInvalidate: Map<Object, () => void> | undefined = new Map();

/**
 * Returns the dynamic imports if they have not already been loaded. If the dynamic imports have not been loaded
 * undefined will be returned.
 */
export function getImports(): DynamicType | undefined {
  return imports;
}

/**
 * Registers a callback that is fired when the dynamic imports have loaded.
 *
 * A callback new callback will override the old callback for an element associated with the callback has already been
 * registered.
 *
 * @param key for tracking the callback owner
 * @param cb the callback function to be called
 */
export function subscribeImportsLoaded(key: Object, cb: () => void) {
  elementsToInvalidate?.set(key, cb);
}

/**
 * Removes the associated callback from the subscribe list.
 * @see subscribeImportsLoaded
 * @param key callback owner
 */
export function unsubscribeImportsLoaded(key: Object) {
  elementsToInvalidate?.delete(key);
}
