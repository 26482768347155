import { __extends } from "tslib";
import { ltjs } from './ltjsInit';
import TypeMap from './TypeMap';
import LTJSModule from './LTJSModule';
export var ltjsModule = new LTJSModule(ltjs);
// reexport for backwards compat?  Not sure if this is needed.
export var _ltjsModule = ltjsModule;
var _registeredModules = [];
var _whenInitializedPromise;
var _preOnReadyDependencies = 0;
var _libraryOnPlatformInitialized = [];
// // loose require packages... for OpenUI use case.
// const _dependenciesToPackageModule: { [key: string]: PortablePackage[] } = {};
var _dependenciesToPackageModule = new Map();
export var typeMap = new TypeMap();
if (_ltjsModule.runtime.typeMapAvailable) {
    typeMap.initFromModuleTypeMap(ltjs.typeMap);
}
else {
    _ltjsModule.runtime.typeMapInitializers.push(function () {
        typeMap.initFromModuleTypeMap(ltjs.typeMap);
    });
}
function _onReadyDependencyComplete() {
    _preOnReadyDependencies--;
    if (_preOnReadyDependencies <= 0) {
        ltjsModule.runtime.executeRunWhenInitializedCallbacks();
    }
}
export function initialize() {
    ltjsModule.runtime.initializeWrappers();
    var allCbs = _libraryOnPlatformInitialized.concat(ltjsModule.runtime.onPlatformInitialized);
    ltjsModule.runtime.platformInitialized = true;
    ltjsModule.runtime.onPlatformInitialized = [];
    _libraryOnPlatformInitialized.length = 0;
    _preOnReadyDependencies = allCbs.length;
    for (var _i = 0, allCbs_1 = allCbs; _i < allCbs_1.length; _i++) {
        var callback = allCbs_1[_i];
        callback(_onReadyDependencyComplete);
    }
    if (_preOnReadyDependencies <= 0) {
        ltjsModule.runtime.executeRunWhenInitializedCallbacks();
    }
}
export function setupLtjsEnvironment() {
    if (ltjsModule.removeBlockingDependency) {
        ltjsModule.removeBlockingDependency('librariesLoaded');
    }
    ltjsModule.libraryLoadingCompleted = true;
}
/**
 * @private
 *
 * Used in lib.js files to register packages.
 *
 * @param {string} namespace corresponding namespace for the packages being registered.
 * @param {Array.<PortablePackage>} packageModules Array of portable packages being registered.
 */
export function registerModule(namespace, packageModules) {
    if (namespace && _registeredModules.indexOf(namespace) < 0) {
        _registeredModules.push(namespace);
    }
    //openUI case
    if (sas.ltjs.registerDependency) {
        sas.ltjs.registerDependency(namespace);
    }
    _dependenciesToPackageModule.set(namespace, packageModules);
}
/**
 * @private
 *
 * @returns {Array.<string>} a list of registered package namespaces
 */
export function getRegisteredModules() {
    return _registeredModules;
}
/**
 * @private
 *
 * Used by ltjsRuntime to initialize PortablePackages
 * @returns {Array.<PortablePackage>} a list of registered PortablePackages
 */
export function getRegisteredPortablePackages() {
    var packages = [];
    for (var _i = 0, _registeredModules_1 = _registeredModules; _i < _registeredModules_1.length; _i++) {
        var moduleName = _registeredModules_1[_i];
        var modulePackages = _dependenciesToPackageModule.get(moduleName);
        if (modulePackages) {
            packages = packages.concat(modulePackages);
        }
    }
    return packages;
}
/**
 * Allows the caller to specify a function to be called after ltjs has been loaded
 * and is ready for use.
 * @param {function} callbackFn the function to be called
 */
export function runWhenInitialized(callbackFn) {
    if (isInitialized()) {
        callbackFn();
    }
    else {
        ltjsModule.runtime.onReadyFunctions.push(callbackFn);
    }
}
/**
 * Returns a promise that is resolved when the ltjsEnvironment has
 * finished initialization.
 * @returns {Promise} initialization promise
 */
export function whenInitialized() {
    if (isInitialized()) {
        return Promise.resolve();
    }
    else {
        if (!_whenInitializedPromise) {
            _whenInitializedPromise = new Promise(function (resolve) {
                ltjsModule.runtime.onReadyFunctions.push(resolve);
            });
        }
        return _whenInitializedPromise;
    }
}
/**
 * Lets the caller know if the ltjs system has been loaded and is ready for use.
 * @returns {boolean} ltjs system is ready
 */
export function isInitialized() {
    return ltjsModule.runtime.ltjsInitialized || isInitializedTestEnvironment();
}
/**
 * When running tests, we must rely on global, rather than module-scoped, variables
 * to keep track of whether the ltjs framework is initialized.
 */
function isInitializedTestEnvironment() {
    return !!sas.ltjs.jest && sas.ltjs.jest.isInitialized;
}
/**
 * @private
 */
export function runWhenPlatformInitialized(callbackFn, libraryInit) {
    if (libraryInit === void 0) { libraryInit = false; }
    if (ltjsModule.runtime.platformInitialized || isInitializedTestEnvironment()) {
        callbackFn();
    }
    else if (libraryInit) {
        _libraryOnPlatformInitialized.push(callbackFn);
    }
    else {
        ltjsModule.runtime.onPlatformInitialized.push(callbackFn);
    }
}
/**
 * @private
 */
export function initializeWrapper(callbackFn) {
    if (ltjsModule.runtime.wrappersInitialized || isInitializedTestEnvironment()) {
        callbackFn();
    }
    else {
        ltjsModule.runtime.wrapperInitFunctions.push(callbackFn);
    }
}
export var typeModuleMap = new Map();
/**
 * Find the module given module path of an ltjs wrapper
 * @param path The path identifier
 * @returns the module.
 */
export function moduleLookup(path) {
    var typeid = typeMap.typeIdReverseLookup(path);
    if (typeid) {
        var module = typeModuleMap.get(typeid);
        if (module === undefined) {
            throw new Error("Module is not defined for typeid: " + typeid);
        }
        return module;
    }
    throw new Error("Cannot lookup module for non truthy typeid: " + typeid);
}
/**
 * Find the module given module path of an ltjs wrapper, returns null if it is not found.
 * @param path The path identifier
 * @returns the module or null.
 */
export function getModuleByPath(path) {
    var typeid = typeMap.typeIdReverseLookup(path);
    if (typeid) {
        var module = typeModuleMap.get(typeid);
        return module || null;
    }
    return null;
}
/**
 * Find the module given module path of an ltjs wrapper, type cast as T. Evaluates module.
 * @param {string} path The path identifier
 * @param {{default:T}} module The imported module
 * @returns {Object} the module.
 */
export function moduleLookupAsDefault(path, module) {
    if (module && module.default) {
        return module.default;
    }
    return moduleLookup(path);
}
export function addClassToModuleMap(path, classToAdd) {
    var id = typeMap.typeIdReverseLookup(path);
    if (id === undefined) {
        return;
    }
    typeModuleMap.set(id, classToAdd);
}
function extendClosetObject(base) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return class_1;
    }(base));
}
/**
 * Used by ltjs wrappers to create a module.
 * @param {Array.<number>} typeIds The inheritance chain of a given wrapper by typeId.
 * @param {[type]} parent The base class of a given chain. This is a hand written class like ClosetObject or EventDispatcher.
 * @returns {function} The newly created class.
 */
export function createModule(typeIds, parent) {
    var module = parent;
    for (var _i = 0, typeIds_1 = typeIds; _i < typeIds_1.length; _i++) {
        var tid = typeIds_1[_i];
        var derived = typeModuleMap.get(tid);
        if (!derived) {
            derived = extendClosetObject(module);
            typeModuleMap.set(tid, derived);
        }
        module = derived;
    }
    return module;
}
/***
 * @private
 *
 * Used in ltjs-jest
 */
export function reinitializeTypemap() {
    typeMap.initFromModuleTypeMap(ltjs.typeMap);
}
