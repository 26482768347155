var TypeMap = /** @class */ (function () {
    function TypeMap() {
        this._reverseLookupMap = new Map();
        this._typeMap = new Map();
    }
    TypeMap.prototype.initFromModuleTypeMap = function (rawTypeMap) {
        var length = rawTypeMap.length;
        for (var tid = 0; tid < length; tid++) {
            var entry = rawTypeMap[tid];
            if (entry) {
                var moduleName = entry[0];
                this._reverseLookupMap.set(moduleName, tid);
                this._typeMap.set(tid, entry);
            }
        }
    };
    TypeMap.prototype.getTypeEntry = function (typeId) {
        return this._typeMap.get(typeId);
    };
    /**
     * Using the given module path of an ltjs wrapper, find the typeId
     * @param {string} path The path identifier
     * @returns {number} the typeId for the given module.
     */
    TypeMap.prototype.typeIdReverseLookup = function (path) {
        return this._reverseLookupMap.get(path);
    };
    return TypeMap;
}());
export default TypeMap;
